<template>
  <div class="headNav displayFlex">
    <div class="nav displayFlex">
      <div class="nav-default" style="cursor: pointer" @click="toggleWords">专业英语测评</div>
      <i class="el-icon-arrow-right nav-icon nav-default"/>
      <div class="">{{ $route.query.name || '' }}</div>
    </div>
    <div class="nav-right">
      <div v-if="$route.query.name === '生词本'">
        <el-input
            v-model="input"
            clearable
            placeholder="请输入单词或释义"
            prefix-icon="el-icon-search"
            style="width: 303px;"
            @input="inputChange"
        />
      </div>
      <div v-else class="displayFlex nav-right-btn">
        <el-button
            :disabled="$route.query.course === '1'"
            size="small"
            @click="switchingCourses('上')"
        >上一课
        </el-button>
        <el-button
            :disabled="$route.query.totalCourse ===$route.query.course"
            size="small" @click="switchingCourses('下')">下一课
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeadNav',
  data() {
    return {
      isSearch: false,
      input: ''
    }
  },
  methods: {
    switchingCourses(type) {
      let course = this.$route.query.course
      if (type === '下') {
        course++
      } else {
        course--
      }
      this.$router.push({
        path: '/wordExerciseDetails',
        query: {name: `第${course}课`, course: course, totalCourse: this.$route.query.totalCourse}
      })
    },
    toggleWords() {
      this.$router.push('/wordExercise')
    },
    inputChange(val) {
      this.$store.commit('SET_WORD_KEY', val)
    }
  }
}
</script>

<style lang="less" scoped>
.displayFlex {
  display: flex;
  align-items: center;
}

.headNav {
  height: calc(100% - 10px);
  justify-content: space-between;

  .nav {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    cursor: default;

    &-default {
      font-size: 18px;
      font-weight: 600;
      color: #FFFFFF;
      opacity: 0.7;
    }

    &-icon {
      padding: 0 12px;
    }
  }

  .nav-right {
    &-btn {
      .el-button {
        border-color: #91BEFF;
        background-color: transparent;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;

        &:hover {
          color: #358BFD;
          background-color: #fff;
        }
      }
    }
  }

}
</style>
