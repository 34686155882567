<template>
  <div class="headShow">
    <div class="headShow-content">
      <div class="headShow-title">专业英语测评</div>
      <div class="headShow-right">
        <div @click="openRecord">
          <img alt="测试记录" src="@/assets/images/jilu_.png">
          <span>测试记录</span>
        </div>
        <div class="break">|</div>
        <div @click="openNewWord">
          <img alt="生词本" src="@/assets/images/shu.png">
          <span>生词本</span>
        </div>
      </div>
    </div>
    <Dialog :title="dialog.title" :visible.sync="dialog.open" :width="dialog.width">
      <div class="record">
        <ul v-if="record.length" class="record-list">
          <li v-for="i in record" :key="i.id" class="record-item">
            <div class="record-item__body">
              <div class="record-item__info">
                <span class="record-item__portrait">{{ getUserName() }}</span>
                <span class="record-item__title">第{{ i.num }}课</span>
              </div>
              <div class="record-item__time">{{ i.createTime }}</div>
            </div>
            <div class="record-item__footer">
              <div class="record-item__footer-right">
                <p class="record-item__footer-item">
                  <img alt="答对" src="@/assets/images/shuyi_zhengque-wancheng.png">
                  <span>答对:{{ i.rightNum }}</span>
                </p>
                <p class="record-item__footer-item">
                  <img alt="打错" src="@/assets/images/cuowu.png">
                  <span>答错:{{ i.errorNum }}</span>
                </p>
                <p class="record-item__footer-item">
                  <img alt="正确率" src="@/assets/images/zhanbi.png">
                  <span>正确率:{{ i.accuracy }}</span>
                </p>
                <p class="record-item__footer-item">
                  <img alt="得分" src="@/assets/images/cz-dftj.png">
                  <span>得分:{{ i.score }}</span>
                </p>
              </div>
              <el-button size="mini" @click="recordDetails(i)">详情</el-button>
            </div>
          </li>
        </ul>
        <el-empty
          v-else
          :image="require('@/assets/images/empty.png')"
          class="none-box"
          description="暂无数据"
          style="min-height: 500px"
        />
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/views/basicSkills/wordExercise/components/Dialog.vue'
import {mapState} from 'vuex'
import {recordListApi} from '@/api/basicSkills/wordExercise'

export default {
  name: 'HeadShow',
  components: {
    Dialog
  },
  data() {
    return {
      dialog: {
        open: false,
        width: '30%',
        title: ''
      },
      record: []
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      articleType: state => state.dict.dictTree.article_type['obj'],
      articleLang: state => state.dict.dictTree.article_lang['obj']
    })
  },
  methods: {
    getRecordList() {
      recordListApi().then(res => {
        this.record = res.rows
      })
    },
    getUserName() {
      const name = this.userInfo.name || ''
      return name.charAt(name.length - 1)
    },
    openRecord() {
      this.getRecordList()
      this.dialog.open = true
      this.dialog.width = '582.31px'
      this.dialog.title = '测试记录'
    },
    recordDetails(item) {
      this.$store.commit('SET_VIEW_TEST_RESULTS', {
        open: true,
        item
      })
      console.log(item)
    },
    openNewWord() {
      this.$router.push({
        path: '/wordExerciseDetails',
        query: { name: `生词本` }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.headShow {
  height: 100%;

  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: calc(100% - 10px);
  }

  &-title {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
  }

  &-right {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;

    > div {
      display: flex;
      align-items: center;
      cursor: pointer;

      > span {
        margin-left: 6px;
      }
    }

    .break {
      color: #84B5FB;
      margin: 0 20px;
      cursor: default;
    }
  }

  .record {
    height: 580px;
    padding: 10px;

    &-list {
      height: 100%;
      overflow: auto;
      padding: 10px;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #E6E6E6;
        -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
      }
    }

    &-item {
      width: 100%;
      height: 121px;
      background: #FFFFFF;
      box-shadow: 0 0 10px 0 rgba(64, 64, 64, 0.15);
      margin-bottom: 20px;
      padding: 0 16px;
      border-radius: 4px;

      &__body {
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &__info {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        display: flex;
        align-items: center;
        width: calc(100% - 150px);

      }

      &__title {
        white-space: nowrap; //禁止换行
        overflow: hidden; //超出隐藏
        text-overflow: ellipsis; //显示省略号
        width: 80%;
      }

      &__portrait {
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        margin-right: 6px;
        background: #6AB3FF;
        border-radius: 50%;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        flex-shrink: 0;
      }

      &__time {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #9099B7;
        flex-shrink: 0;
        width: 150px;
        text-align: right;
      }

      &__footer {
        height: 50px;
        border-top: 1px solid #EFF2F8;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-right {
          display: flex;
          align-items: center;
        }

        &-item {
          display: flex;
          align-items: center;
          margin-right: 20px;

          span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #8A94B3;
            margin-left: 4px;
          }
        }
      }
    }
  }
}
</style>
