import request from "@/utils/request";

/*
* 有道发音
* */
export const wordPronunciationApi = params => request({
    url: `/api/teacher/word/voice`,
    method: 'GET',
    params,
    responseType: 'blob'
})
/*
* 单词练习 首页列表
* */
export const wordListApi = () => request({
    url: `/api/word/practice/info`,
    method: 'GET',
})
/*
* 单词练习 测试结果
* */
export const recordListApi = () => request({
    url: `/api/word/test/record/list`,
    method: 'GET',
    params: {
        pageNum: 1,
        pageSize: 9999,
    }
})

/*
* 单词练习 查看课时详情
* */
export const recordPracticeApi = (data) => request({
    url: `/api/word/practice`,
    method: 'POST',
    data
})

/*
* 单词练习 生词本列表
* */
export const neologismApi = (params) => request({
    url: `/api/word/neologism/list`,
    method: 'GET',
    params
})

/*
* 单词练习 加入生词本
* */
export const addWordApi = (wordId) => request({
    url: `/api/word/neologism/add/${wordId}`,
    method: 'GET',
})
/*
* 单词练习 移除生词本
* */
export const removeWordApi = (wordId) => request({
    url: `/api/word/neologism/remove/${wordId}`,
    method: 'DELETE',
})
/*
* 单词练习 保存测试结果
* */
export const addRecordApi = (data) => request({
    url: `/api/word/test/record/add`,
    method: 'POST',
    data
})
/*
* 单词练习 查看测试结果
* */
export const testRecordApi = (recordId) => request({
    url: `/api/word/test/record/${recordId}`,
    method: 'GET',
})
