<template>
  <div class="wordExercise">
    <Carousel center width="1200px" />
    <div ref="wordExerciseContent" class="wordExercise-content">
      <div class="wordExercise-content__head">
        <HeadShow v-if="$route.path === '/wordExercise'" />
        <HeadNav v-else />
      </div>
      <div ref="wordExerciseContentBody" class="wordExercise-content__body">
        <router-view />
      </div>
    </div>
    <indexBottom />
  </div>
</template>

<script>
import Carousel from '@/components/Carousel.vue'
import indexBottom from '@/components/indexBottom.vue'
import HeadShow from '@/views/basicSkills/wordExercise/components/HeadShow.vue'
import HeadNav from '@/views/basicSkills/wordExercise/components/HeadNav.vue'

/**
 * 单词练习
 */
export default {
  name: 'WordExercise',
  components: {
    Carousel,
    indexBottom,
    HeadShow,
    HeadNav
  },

  mounted() {
  }
}
</script>

<style lang="less" scoped>
.wordExercise {
  margin-top: 80px;
  text-align: left;

  &-content {
    width: 1200px;
    min-height: 726px;
    margin: 30px auto;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 10px;
      position: absolute;
      top: 62px;
      left: 0;
      border-radius: 8px 8px 0 0;
      background-color: #fff;
    }

    &__head {
      height: 72px;
      background: linear-gradient(98deg, #137CFF 0%, #5397F9 100%);
      box-shadow: 0 8px 10px 0 rgba(40, 113, 234, 0.1);
      border-radius: 8px 8px 0 0;
      padding: 0 30px;
    }

    &__body {
      border-radius: 0 0 8px 8px;
      //position: absolute;
      //left: 0;
      //top: 62px;
      width: 100%;
      background: #FFFFFF;
      box-shadow: 0 8px 10px 0 rgba(40, 113, 234, 0.1);
      //border-radius: 8px;
    }
  }
}
</style>
